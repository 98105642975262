<template>
  <div id="about" class="decorated">
    <HeaderNavigation title="à propos d'Evenir" />
    <b-container class="main-container">
      <b-row class="no-gutters">
        <b-col class="body">
          <p>
            Evenir propose la première plateforme communautaire de points de
            recharge privatifs mis à disposition des utilisateurs de véhicules
            électriques. Quiconque possède un point de recharge ou une simple
            prise de courant peut la déclarer sur l’application.
          </p>
          <p>
            Nous permettons de rendre le réseau privatif de recharge accessible
            au public en mettant en relation des hôtes de points de recharge
            (particuliers, copropriété, entreprises, établissements publics)
            avec des utilisateurs (conducteurs de véhicules électriques).
          </p>
          <p>
            Grâce à cette plateforme, nous déverrouillons le réseau de recharge
            du jour au lendemain sans investissement majeur, une simple prise de
            courant pouvant être déclarée. Nous rendons accessible au public un
            réseau "dormant" déjà existant qui vient répondre à un problème
            majeur du développement du véhicule électrique, l’accès à un point
            de recharge quel que soit l’endroit où l’on se trouve.
          </p>
          <p>
            Que vous soyez utilisateur ou hôte du service, notre plateforme
            s’utilise de manière très simple et intuitive :
          </p>
          <ul>
            <li>
              Aucune dépendance matérielle ou technologique. Un point de
              recharge ou une simple prise de courant peuvent être déclarées et
              utilisables au travers de l’application.
            </li>
            <li>
              L’algorithme mis au point permet d’assurer le suivi et le contrôle
              du temps passé sur le point de recharge, ce qui apporte une réelle
              sécurité pour tous les utilisateurs.
            </li>
            <li>
              Les transactions sont automatiques, il n’y a pas besoin de valider
              un paiement ni le besoin de carte d’abonnement, tout est
              dématérialisé.
            </li>
            <li>
              L’application peut s’utiliser en réseau fermé, les copropriétés et
              les entreprises peuvent maintenant rendre disponible leurs prises
              de courant aux personnes autorisées uniquement, tout en ayant la
              possibilité de comptabiliser la consommation de chacun.
            </li>
          </ul>
          <p>
            Enfin, en favorisant des recharges de puissance raisonnable, nous
            limitons l'impact sur le réseau électrique, optimisant nos moyens de
            production. Il s'agit d'un modèle qui favorise une société plus
            collaborative et décentralisée basée sur le partage et
            l'optimisation des ressources déjà existantes.
          </p>
          <p>Rejoignez la communauté Evenir.</p>
          <p class="text-center">
            <img src="@/assets/img/about.png" />
          </p>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import HeaderNavigation from '@/components/HeaderNavigation.vue'

export default {
  name: 'about',
  components: { HeaderNavigation }
}
</script>
<style lang="scss" scoped>
@import '@/styles.scss';

#about {
  .body {
    font-family: $font-family;
    font-weight: $font-weight-regular;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.7px;
    color: $text-light;

    img {
      max-width: 100%;
    }
  }
}
</style>
