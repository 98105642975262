var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "decorated", attrs: { id: "about" } },
    [
      _c("HeaderNavigation", { attrs: { title: "à propos d'Evenir" } }),
      _c(
        "b-container",
        { staticClass: "main-container" },
        [
          _c(
            "b-row",
            { staticClass: "no-gutters" },
            [
              _c("b-col", { staticClass: "body" }, [
                _c("p", [
                  _vm._v(
                    " Evenir propose la première plateforme communautaire de points de recharge privatifs mis à disposition des utilisateurs de véhicules électriques. Quiconque possède un point de recharge ou une simple prise de courant peut la déclarer sur l’application. "
                  )
                ]),
                _c("p", [
                  _vm._v(
                    " Nous permettons de rendre le réseau privatif de recharge accessible au public en mettant en relation des hôtes de points de recharge (particuliers, copropriété, entreprises, établissements publics) avec des utilisateurs (conducteurs de véhicules électriques). "
                  )
                ]),
                _c("p", [
                  _vm._v(
                    ' Grâce à cette plateforme, nous déverrouillons le réseau de recharge du jour au lendemain sans investissement majeur, une simple prise de courant pouvant être déclarée. Nous rendons accessible au public un réseau "dormant" déjà existant qui vient répondre à un problème majeur du développement du véhicule électrique, l’accès à un point de recharge quel que soit l’endroit où l’on se trouve. '
                  )
                ]),
                _c("p", [
                  _vm._v(
                    " Que vous soyez utilisateur ou hôte du service, notre plateforme s’utilise de manière très simple et intuitive : "
                  )
                ]),
                _c("ul", [
                  _c("li", [
                    _vm._v(
                      " Aucune dépendance matérielle ou technologique. Un point de recharge ou une simple prise de courant peuvent être déclarées et utilisables au travers de l’application. "
                    )
                  ]),
                  _c("li", [
                    _vm._v(
                      " L’algorithme mis au point permet d’assurer le suivi et le contrôle du temps passé sur le point de recharge, ce qui apporte une réelle sécurité pour tous les utilisateurs. "
                    )
                  ]),
                  _c("li", [
                    _vm._v(
                      " Les transactions sont automatiques, il n’y a pas besoin de valider un paiement ni le besoin de carte d’abonnement, tout est dématérialisé. "
                    )
                  ]),
                  _c("li", [
                    _vm._v(
                      " L’application peut s’utiliser en réseau fermé, les copropriétés et les entreprises peuvent maintenant rendre disponible leurs prises de courant aux personnes autorisées uniquement, tout en ayant la possibilité de comptabiliser la consommation de chacun. "
                    )
                  ])
                ]),
                _c("p", [
                  _vm._v(
                    " Enfin, en favorisant des recharges de puissance raisonnable, nous limitons l'impact sur le réseau électrique, optimisant nos moyens de production. Il s'agit d'un modèle qui favorise une société plus collaborative et décentralisée basée sur le partage et l'optimisation des ressources déjà existantes. "
                  )
                ]),
                _c("p", [_vm._v("Rejoignez la communauté Evenir.")]),
                _c("p", { staticClass: "text-center" }, [
                  _c("img", {
                    attrs: { src: require("@/assets/img/about.png") }
                  })
                ])
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }